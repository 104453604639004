import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Drawer from "components/Drawer/drawer"
import { DrawerContext } from "components/Drawer/drawerContext"
import DemoCard from "../../components/DemoCard/demoCard"
import Button from "components/Button/button"
import { FiX } from "react-icons/fi"
import {
  DemoSwitcherWrapper,
  SwitcherContent,
  SwitcherHead,
  SwitcherTitle,
  SwitcherClose,
  DemosWrapper,
  ButtonWrapper,
  SwitcherButton,
  DemoCol,
} from "./style"

type DemoSwitcherProps = {}

const DemoSwitcher: React.FunctionComponent<DemoSwitcherProps> = () => {
  const Data = useStaticQuery(graphql`
    query {
      dataJson {
        demos {
          id
          title
          url
          badge
          image {
            childImageSharp {
              fluid(maxWidth: 570, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  const { state, dispatch }: any = useContext(DrawerContext)

  // Toggle drawer
  const toggleDrawer = () => {
    dispatch({
      type: "TOGGLE",
    })
  }

  return (
    <DemoSwitcherWrapper>
      <Drawer
        width="430px"
        placement="right"
        drawerHandler={<SwitcherButton>DEMOS</SwitcherButton>}
        open={state.isOpen}
        toggleHandler={toggleDrawer}
        className="demo-drawer"
      >
        <SwitcherContent>
          <SwitcherHead>
            <SwitcherTitle>Explore other Demos</SwitcherTitle>
            <SwitcherClose onClick={toggleDrawer}>
              <FiX />
            </SwitcherClose>
          </SwitcherHead>
          <DemosWrapper>
            {Data.dataJson.demos.map((demo: any) => (
              <DemoCol key={demo.id}>
                <DemoCard
                  title={demo.title}
                  url={demo.url}
                  badge={demo.badge}
                  alt={demo.title}
                  image={
                    demo.image !== null || undefined
                      ? demo.image.childImageSharp.fluid
                      : ""
                  }
                />
              </DemoCol>
            ))}
          </DemosWrapper>
          <ButtonWrapper>
            <a href="https://1.envato.market/r1jdv" target="_blank">
              <Button title="Purchase Now" />
            </a>
          </ButtonWrapper>
        </SwitcherContent>
      </Drawer>
    </DemoSwitcherWrapper>
  )
}

export default DemoSwitcher
