import * as React from "react"
import Img from "gatsby-image"
import { DemoCardWrapper, DemoImage, DemoTitle, Badge } from "./demoCard.style"

type DemoCardProps = {
  image: any
  alt?: string
  title: string
  url: string
  className?: string
  badge?: string
}

const DemoCard: React.FunctionComponent<DemoCardProps> = ({
  image,
  alt,
  title,
  url,
  className,
  badge,
}) => {
  return (
    <DemoCardWrapper className={className}>
      <a href={url} target="_blank">
        <DemoImage className='demo-image'>
          {image !== null || undefined ? (
            <Img fluid={image} alt={alt}></Img>
          ) : (
            ""
          )}
          {badge && <Badge>{badge}</Badge>}
        </DemoImage>
        <DemoTitle>{title}</DemoTitle>
      </a>
    </DemoCardWrapper>
  )
}

export default DemoCard
