import styled from "styled-components"

export const DemoCardWrapper = styled.div`
  position: relative;
  &:hover {
    .demo-image {
      &:before {
        filter: blur(15px);
      }
    }
  }
`

export const DemoImage = styled.div`
  position: relative;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  margin-bottom: 15px;
  .gatsby-image-wrapper {
    border-radius: 6px;
  }
  &:before {
    content: "";
    display: block;
    position: absolute;
    width: 70%;
    height: 70%;
    bottom: 0;
    left: 15%;
    background: #555555;
    transition: 0.25s ease-in-out;
  }
`

export const DemoTitle = styled.h3`
  font-size: 13px;
  font-weight: 600;
  color: #292929;
  margin: 0;
  text-align: center;
`

export const Badge = styled.div`
  position: absolute;
  top: 5px;
  left: 5px;
  background: #4b6deb;
  color: #fff;
  padding: 5px 10px;
  border-radius: 3px;
  text-align: center;
  font-size: 13px;
`
