import styled from "styled-components"

export const DemoSwitcherWrapper = styled.div`
  display: block;
  position: fixed;
  top: 50%;
  right: 0;
  z-index: 999;
  transform: translateY(-50%);
`

export const SwitcherContent = styled.div`
  padding: 30px;
`

export const SwitcherHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 30px;
`

export const SwitcherTitle = styled.h3`
  font-size: 18px;
  font-weight: 700;
  color: #292929;
  margin: 0;
`

export const SwitcherClose = styled.div`
  cursor: pointer;
  font-size: 20px;
  color: #757575;
  svg {
    display: block;
  }
`

export const DemosWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`

export const DemoCol = styled.div`
  flex: 0 0 calc(50% - 5px);
  max-width: calc(50% - 5px);
  margin-bottom: 30px;
`

export const ButtonWrapper = styled.div`
  margin-top: 30px;
  text-align: center;
`

export const SwitcherButton = styled.div`
  display: block;
  padding: 10px 12px;
  font-size: 12px;
  letter-spacing: 0.17em;
  background-color: #f73b98;
  color: #fff;
  writing-mode: vertical-rl;
  text-orientation: upright;
  cursor: pointer;
  transition: 0.15s ease-in-out;
  &:hover {
    background-color: #d10068;
  }
`
